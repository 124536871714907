import { template as template_64ebf46af7cf4aba8f5ade9778e87482 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_64ebf46af7cf4aba8f5ade9778e87482(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;

import { template as template_901dc580534446f6a0f1caf1e4d8bfb1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_901dc580534446f6a0f1caf1e4d8bfb1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_9de9e30268c44429b136db0398e99d39 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9de9e30268c44429b136db0398e99d39(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
